import { format, formatDistanceToNow } from "date-fns";
import ele from "./ele";
import { foodsStore } from "./store";

let intervalUpdateTimeElapsed

/**
 * Starts fasting mode if localStorage fasting is set to true.
 * Adds a button to the provided element.
 * Renders all nessesary UIs.
 * 
 * @param {HTMLElement} _element - HTML element to insert "Start fasting" button to
 */
export function installFasting(_element) {
    ele.insert(ele.create('button', {
        classList: 'naked',
        onclick: ()=> {
            startFasting()
        }
    }, 'Start fasting'), _element)

    try {
        let fast = JSON.parse(localStorage.getItem('fast'))
    
        if(fast.fasting) {
            renderFastingUi(fast.started)
        }
    } catch {}
}

function timeSinceLastFoodAdded() {
    let arrayOfAllTimes = foodsStore.map(food => food.time_added).filter(time => time > 1)
    if(arrayOfAllTimes.length === 0) return Date.now()
    return Math.max(...arrayOfAllTimes)
}



function renderFastingUi(_started) {
    let fastStarted = _started
    let EL_FASTING = ele.create('div', {id: 'fasting'})
    ele.insert(EL_FASTING)
    ele.insert(`
    <style>
    
    #fasting {
        text-align: center;
        background: #F6F4EF;
        position: fixed;
        left: 0px;
        top: 0px;
        height: 100vh;
        width: 100%;
        z-index: 9999;
        overflow-y: scroll;
    }
    </style>
    <h1>🍵</h1>
    <h1>No snacking now</h1>
    <div id="fastElapsedTime">Fasting started ${formatDistanceToNow(fastStarted)} ago</div>
    <br>
    <div class="faded">${format(fastStarted, "'on' eeee 'at' HH:mm:ss")}</div>
    <br>
    <br>
    <div id="lastFoodEntry">Last food entry ${formatDistanceToNow(timeSinceLastFoodAdded())} ago</div>
    <br>
    <div class="faded">${format(timeSinceLastFoodAdded(), "'on' eeee 'at' HH:mm:ss")}</div>
    <br>
    <br>
    `, EL_FASTING)

    let BTN_STOP_FASTING = ele.create('button',{
        onclick: () => stopFasting()
    }, 'Stop fasting')

    ele.insert(BTN_STOP_FASTING, EL_FASTING)

    intervalUpdateTimeElapsed = setInterval(() => {
        document.getElementById('fastElapsedTime').innerHTML = `Fasting started ${formatDistanceToNow(fastStarted)} ago`
        document.getElementById('lastFoodEntry').innerHTML = `Last food entry ${formatDistanceToNow(timeSinceLastFoodAdded())} ago`
    }, 10000);

    document.getElementById('calorie-counter').remove()
}

export function startFasting() {
    let fast = {
        fasting: true,
        started: Date.now()
    }
    localStorage.setItem('fast', JSON.stringify(fast))
    renderFastingUi(Date.now())
}


function stopFasting() {
    if(confirm('Stop fasting?')) {
        let fast = JSON.parse(localStorage.getItem('fast'))
        fast.fasting = false
    
        localStorage.setItem('fast', JSON.stringify(fast))
        document.getElementById('fasting').remove()
        clearInterval(intervalUpdateTimeElapsed);

        location.reload()
    }
}
class Ele {
    create(_element = 'div', _settings = {}, _inner = '') {

        let element = document.createElement(_element)
            element.innerHTML = _inner

        Object.entries(_settings).forEach(setting => {
            const [key, value] = setting;
            element[key] = value
        });

        return element
    }

    insert(_element, _target = document.body, _insert = 'beforeEnd') {
        if(typeof _element === 'object') {
            _target.insertAdjacentElement(_insert, _element);
        } else {
            _target.insertAdjacentHTML(_insert, _element);
        }
    }
    replace(_old, _new) {
        if(_old.split('')[0] == '#') {
            document.getElementById(_old.split('#')[1]).replaceWith(_new)
        }
    }
}

export default window.ele = new Ele()
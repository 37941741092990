const API_KEY = 'hbXaL8ixylNlJgf8aXoZ/g==3FbuKHJvFIW00q83'

export async function fetchFood(query) {
    const rawResponse = await fetch('https://api.calorieninjas.com/v1/nutrition?query='+query, {
        method: 'GET',
        headers: {
        'X-Api-Key': 'hbXaL8ixylNlJgf8aXoZ/g==3FbuKHJvFIW00q83', 
        'Content-Type': 'application/json'
        }
    });
    const content = await rawResponse.json();

    console.log(content.items[0]);

    return content.items[0]
}

// export async function fetchFood(query) {
//     const rawResponse = await fetch('https://trackapi.nutritionix.com/v2/natural/nutrients', {
//         method: 'POST',
//         headers: {
//         'x-app-id': '038f9f34',
//         'x-app-key': 'ebcd23afaab7d73c2574838d10d0f145', 
//         'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({"query": query})
//     });
//     const content = await rawResponse.json();

//     console.log(content.foods[0]);

//     return content.foods[0]
// }



import { foodsStore, getFoods } from "./store";

export function id() {
    return Math.random().toString(36).substr(2, 6);
}

const total = () => {
    // let counted = foods.filter(food => food.consumed === true).map(food => food.calories)
    return foodsStore.filter(food => food.consumed === true).reduce((acc, food) => acc + food.calories, 0)

    return 0
}

export const renderTotal = () => ele.create('h2', {id: 'total-calories'}, `${total()} cal`)


import { foodsStore, getFoods, setFoodsStore } from "./store";
import { renderTotal } from "./utils";

export function removeFood(event, id) {
    if (window.confirm("Delete this item?")) {
        let newArray = foodsStore.filter(food => food.id !== id)
        setFoodsStore(newArray)
        event.target.parentElement.remove()
        ele.replace('#total-calories', renderTotal())
    }
} 
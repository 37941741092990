import { templateFoodRow } from "./food-row"
import { id } from "./utils"

export let foodsStore = []

export const setFoodsStore = (_foodsArray) => {
  foodsStore = _foodsArray
  updateLocalStorage()
}

export const addFood = (_name, _amount, _measurement, _calories) => {
  let food = {
      id: id(),
      name: _name,
      amount: _amount,
      measurement: _measurement,
      calories: _calories,
      consumed: false,
      time_added: Date.now()
  }
  foodsStore.push(food)
  updateLocalStorage()
  templateFoodRow(food, document.getElementById('food-list'))
}

function updateLocalStorage() {
  localStorage.setItem('foods', JSON.stringify(foodsStore));
}


import { templateFoodRow } from "./food-row"
import { foodsStore, getFoods, setFoodsStore } from "./store"

export function renderFoodList(_element) {
    if(localStorage.getItem('foods')) {
        let ls = localStorage.getItem('foods')
        setFoodsStore(JSON.parse(ls))

        _element.innerHTML = '';
   
        foodsStore.forEach(food => {
            templateFoodRow(food, _element)
        });
        
    } else {

    }
}
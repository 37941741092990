import ele from "./ele"
import { fetchFood } from "./fetch"
import { addFood } from "./store"


const EL_INPUT_SEARCH_QUERY = () => document.getElementById('inputSearch')
const EL_FOOD_NAME = () => document.getElementById('foodName')
const EL_INPUT_AMOUNT_GRAMS = () => document.getElementById('inputGrams')
const EL_INPUT_CALORIES = () => document.getElementById('inputCalories')

const EL_DETAILS = () => document.getElementById('food__details')
const BTN_ADD_FOOD = () => document.getElementById('addFoodBtn')

const addFoodForm = `
  <input id=inputSearch placeholder="e.g. 50g of oats" class="large">
  <div id=food__details></div>
  <button id=addFoodBtn style="display: none;">Add</button>

`

export function renderAddFoodForm(_element) {
  ele.insert(addFoodForm, _element)

  // EL_INPUT_SEARCH_QUERY().addEventListener('blur', ()=> {
  //   console.log(EL_INPUT_SEARCH_QUERY().innerText)
  //   if(EL_INPUT_SEARCH_QUERY().innerText.length < 1) {
  //     BTN_ADD_FOOD().style.display = 'none'
  //     EL_DETAILS().innerText = ''
  //   } 
  // })

  document.getElementById('addFoodBtn').addEventListener('click', ()=> {
    let name = EL_FOOD_NAME().innerText
    let amount = EL_INPUT_AMOUNT_GRAMS().value
    let calories = EL_INPUT_CALORIES().value

    console.log(calories)
    
    if(name == '' || calories == '' || amount == '') {
      alert('Fill in all details please.')
    } else {
      addFood(name, parseInt(amount), 'grams', parseInt(calories))
      BTN_ADD_FOOD().style.display = 'none'
      EL_DETAILS().innerText = ''
      EL_INPUT_SEARCH_QUERY().value = ''
    }
    
  })


  // Init a timeout variable to be used below
  let timeout = null;
 
  // Listen for keystroke events
  EL_INPUT_SEARCH_QUERY().addEventListener('keyup', function (e) {
      // Clear the timeout if it has already been set.
      // This will prevent the previous task from executing
      // if it has been less than <MILLISECONDS>

      if(EL_INPUT_SEARCH_QUERY().value.length === 0) {
        BTN_ADD_FOOD().style.display = 'none'
        EL_DETAILS().innerText = ''
      }


      clearTimeout(timeout);

      // Make a new timeout set to go off in 1000ms (1 second)
      timeout = setTimeout(function () {

        if(EL_INPUT_SEARCH_QUERY().value.length > 2) {
          EL_INPUT_SEARCH_QUERY().classList.add('animate');
          EL_INPUT_SEARCH_QUERY().classList.remove('error');


          (async () => {
            let data = await fetchFood(EL_INPUT_SEARCH_QUERY().value);
            
            if(data == null) {
              EL_INPUT_SEARCH_QUERY().classList.remove('animate');
              EL_INPUT_SEARCH_QUERY().classList.add('error')
              return
            }
            
            EL_INPUT_SEARCH_QUERY().classList.remove('error');

            let name = data.name
            let calories = data.calories
            let quantity = data.serving_size_g
            let unit = 'grams'
            let grams = data.serving_size_g

            let template = ele.create('div', {id: 'food__details'},`
              <h3 id=foodName>${name}</h3>
              <p class="faded">${calories} calories in ${quantity} ${unit} of ${name}.</p>
              <div>
                <span class="name">Calories</span> 
                <input id=inputCalories value='${calories}' type="number" disabled>
              </div>
              <div>
                <span class="name">Grams</span>
                <input id=inputGrams value='${grams}' type=number>
              </div>
            `)

            ele.replace('#food__details', template)

            BTN_ADD_FOOD().style.display = 'block'
            EL_INPUT_SEARCH_QUERY().classList.remove('animate')

            const caloriesPerGram = EL_INPUT_CALORIES().value / EL_INPUT_AMOUNT_GRAMS().value
            EL_INPUT_AMOUNT_GRAMS().addEventListener('keyup', function (e) {
              console.log(caloriesPerGram)
              let updatedCalories = caloriesPerGram * EL_INPUT_AMOUNT_GRAMS().value
              EL_INPUT_CALORIES().value = updatedCalories.toFixed(1) 
            });

          })();
        }
      }, 600);
  });



}


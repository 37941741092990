import { removeFood } from "./remove"
import { foodsStore, setFoodsStore } from "./store"
import { renderTotal } from "./utils"

export function templateFoodRow(_food, _appendTo) {
    const foodRow = ele.create('div', {
        onclick: (event) => toggleConsumed(event.currentTarget, event),
        onmouseenter: (event) => toggleConsumedHover(event.currentTarget, event.buttons, event),
        classList: _food.consumed ? 'food-row consumed' : 'food-row'
    })
    
    foodRow.dataset.name = _food.name
    foodRow.dataset.id = _food.id
    
    const btnRemoveFood = ele.create('button', {
        classList: 'delete',
        onclick: (event) => removeFood(event, event.target.dataset.id)
    }, '×')
    btnRemoveFood.dataset.id = _food.id
    
    ele.insert(`<div class="food-row__details"><b>${_food.name}</b> ${_food.amount}${_food.measurement} (${_food.calories}cal)`, foodRow)
    ele.insert(btnRemoveFood, foodRow)
    ele.insert(foodRow, _appendTo)
}

function toggleConsumed(element, event) {
    event.stopPropagation();
    if(event.target.classList.contains('delete')) return
    let object = foodsStore.find(f => f.id === element.dataset.id)
    object.consumed = !object.consumed
    element.classList.toggle('consumed')
    ele.replace('#total-calories', renderTotal())
    setFoodsStore(foodsStore)
}

function toggleConsumedHover(element, buttons, event) {
    if (buttons === 1) {
        toggleConsumed(element, event)
    }
}
import './theme.css'
import ele from './ele'
import { renderAddFoodForm } from './add-form'
import { renderTotal } from './utils'
import { renderFoodList } from './food-list'
import { installFasting } from './fasting'

const calorieCounterApp = ele.create('div', {id: 'calorie-counter'})
const foodAdd = ele.create('div', {id: 'food__add'})
const foodDiv = ele.create('div', {id: 'food-list'})
const foodFooter = ele.create('div', {id: 'food-footer'})
const foodSummary = ele.create('div', {id: 'food-summary'})

ele.insert(foodAdd, calorieCounterApp)
ele.insert(foodDiv, calorieCounterApp)
ele.insert(foodFooter, calorieCounterApp)
ele.insert(foodSummary, calorieCounterApp)

ele.insert(calorieCounterApp, document.body)

renderAddFoodForm(foodAdd)
renderFoodList(foodDiv)

ele.insert(renderTotal(), foodSummary)

installFasting(foodSummary)

// ele.insert(
//     ele.create('button', {
//         onclick: () => {
//             localStorage.clear()
//             location.reload(); 
//         }
//     }, 'Reset app'),
    
//     sectionCalorieCounter
// )









